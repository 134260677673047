<template>
  <div @click.stop="cancelActive">
    <admin-title></admin-title>
    <div class="container" style="display: block;" >
      <div class="page-title">
        <span>エントリー管理</span>
        <div class="export">
          <div @click.stop="handleBasicExport()" class="export-title">
            <span>エクスポート</span>
          </div>
<!--          <div @click.stop="changeExportActive" :class="exportActive ? 'active' : ''" class="export-list">
            <p>CSV</p>
          </div>-->
        </div>
      </div>

      <div class="pagination">
        <p></p>
<!--        <p>保存リスト ＞ 新規作成 ＞ 学生一覧</p>-->
        <el-pagination
            :page-size="search.pageSize"
            :pagerCount="5"
            layout="pager"
            :current-page="search.pageIndex"
            @current-change="handleCurrentChange"
            :total="search.pageCount">
        </el-pagination>
      </div>

      <div class="note-list">
        <div class="note-left">
          <div>
            <p class="left-title">検索条件設定</p>
            <div class="input-item">
              <div class="input-title">
                <span>卒業年度</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="卒業年度で検索が可能です。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="graduationYear" multiple class="select" v-model="search.graduationYear" placeholder="">
                <el-option v-for="(item, index) in searchItem.graduationYear" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>


            </div>

            <div class="input-item">
              <div class="input-title">
                <span>学校区分</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="現在在学中の学校区分で検索ができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="schoolDivision" multiple class="select" v-model="search.schoolDivision" placeholder="">
                <el-option v-for="(item, index) in searchItem.schoolDivision" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>ステータス</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="学生のステータスで検索ができます。フロー運用で新たに作成したフローは設定できません。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="kbCode" multiple class="select" v-model="search.detailContent" placeholder="">
                <el-option v-for="(item, index) in searchItem.detailContent" :key="index" :label="item.kbName" :value="item.kbCode"></el-option>
              </el-select>
            </div>
            <!--          <el-radio class="select-progress" v-model="radio" label="1">内定者を除く</el-radio>-->
            <div class="input-item date">
              <div class="input-title">
                <span>エントリー期間</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="エントリー期間で検索が可能です。上段は「どの日付から検索するか」、下段は「どの日付まで検索するか」、日付を設定します（上段/下段のいずれかのみの設定も可能です）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-date-picker
                  v-model="search.entryDateFrom"
                  type="date"
                  format="yyyy/MM/dd"
                  :clearable="true"
                  value-format="yyyy-MM-dd"
                  placeholder="年/月/日"
                  end-placeholder="年/月/日">
              </el-date-picker>
              <el-date-picker
                  v-model="search.entryDateTo"
                  type="date"
                  format="yyyy/MM/dd"
                  :clearable="true"
                  value-format="yyyy-MM-dd"
                  placeholder="年/月/日"
                  end-placeholder="年/月/日">
              </el-date-picker>
            </div>
            <el-checkbox-group v-model="search.group">
              <div class="groups">
                <el-checkbox label="studentOnlyFlg" class="select-progress checkbox">担当学生のみ</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="自分が担当になっている学生のみを検索することができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="groups">
                <el-checkbox label="exceptInternalFlg" class="select-progress checkbox">内定者を除く</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="ステータスが「内定」となっている学生を除外することができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="groups">
                <el-checkbox label="exceptDismissFlg" class="select-progress checkbox">辞退者を除く</el-checkbox>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="ステータスが「辞退」となっている学生を除外することができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>

            </el-checkbox-group>
            <div class="input-item">
              <div class="input-title">
                <span>希望年収</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望年収で検索が可能です。下限額と上限額の設定ができます。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <div class="money-ranger">
                <el-input type="number" v-model="search.expectedAnnualSalaryMin" placeholder="200"></el-input>
                <p>〜</p>
                <el-input type="number" v-model="search.expectedAnnualSalaryMax" placeholder="350"></el-input>
              </div>
            </div>
            <!--          <div class="input-item select-progress">-->
            <!--            <div class="input-title">-->
            <!--              <span>タスク未対応</span>-->
            <!--              <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="フロー設計のデフォルト項目。『説明会エントリー / エントリー / 面接 / 内定』 からドロップダウンで1つ選択が可能。" placement="right-end">-->
            <!--                <span><img src="../../assets/images/entryLevel/ques.png" /></span>-->
            <!--              </el-tooltip>-->
            <!--            </div>-->
            <!--            <el-select class="select" v-model="value" placeholder="">-->
            <!--              <el-option label="社内選考中" value="社内選考中"></el-option>-->
            <!--            </el-select>-->
            <!--          </div>-->




            <div class="input-item">
              <div class="input-title">
                <span>希望業種</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望業種で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="expectedIndustry" multiple  class="select" v-model="search.expectedIndustry" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedIndustry" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item">
              <div class="input-title">
                <span>希望職種</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望職種で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="expectedOccupation" multiple class="select" v-model="search.expectedOccupation" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedOccupation" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item">
              <div class="input-title">
                <span>希望勤務地</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="希望勤務地で検索が可能です（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="expectedWorkplace" multiple class="select" v-model="search.expectedWorkplace" placeholder="">
                <el-option v-for="(item, index) in searchItem.expectedWorkplace" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>一緒に働きたい社長像</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="一緒に働きたい社長像のテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="mostImportant" multiple class="select" v-model="search.mostImportant" placeholder="">
                <el-option v-for="(item, index) in searchItem.mostImportant" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>就職活動で企業選びの軸にしていること</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="就職活動で企業選びの軸にしていることのテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="importanceOfComp" multiple class="select" v-model="search.importanceOfComp" placeholder="">
                <el-option v-for="(item, index) in searchItem.importanceOfComp" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>

            <div class="input-item interview">
              <div class="input-title">
                <span>学生時代に力を入れたこと</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="学生時代に力を入れたことのテーマで検索することができます（複数選択可能）。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-select value-key="studentEffort" multiple class="select" v-model="search.studentEffort" placeholder="">
                <el-option v-for="(item, index) in searchItem.studentEffort" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>


            <div class="input-item input-bottom">
              <div class="input-title">
                <span>キーワード検索</span>
                <el-tooltip :visible-arrow="false" class="input-label" effect="dark" content="フリーワード検索が可能です。フリーワードは1つだけ入力してください。" placement="right-end">
                  <span><img src="../../assets/images/entryLevel/ques.png" /></span>
                </el-tooltip>
              </div>
              <el-input v-model="search.keyword" placeholder="学部、学科、コース/専攻、免許/資格 など"></el-input>
            </div>

            <div class="left-bottom">
              <div @click="searchList" class="search">検索する</div>
              <a @click="clear()">検索条件をクリア</a>
            </div>
          </div>
        </div>
        <div class="note-right">
          <div class="page-result">
            <div class="search-result">
              <span class="search-title">検索結果</span>
              <span class="search-num">{{search.pageCount}}</span>
            </div>
            <div class="page-size">
              <p>表示件数　<i @click="changePageSize(30)" :class="search.pageSize == 30 ? 'active' : ''">30件</i>｜
                <i @click="changePageSize(50)" :class="search.pageSize == 50 ? 'active' : ''">50件</i>｜
                <i @click="changePageSize(100)" :class="search.pageSize == 100 ? 'active' : ''">100件</i></p>
            </div>
          </div>
          <el-dropdown trigger="click" placement="bottom">
            <div class="icon">
              <img class="star-grey" v-if="search.saveFlg == 1" src="../../assets/images/entryLevel/star_orange.png" />
              <img v-else class="star-grey" src="../../assets/images/entryLevel/star_grey.png" />
              <img class="arrow" src="../../assets/images/entryLevel/arrow_down.png" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="selectIsFlg(1)">
                <img src="../../assets/images/entryLevel/star_orange.png" />
              </el-dropdown-item>
              <el-dropdown-item @click.native="selectIsFlg(-1)">
                <img  src="../../assets/images/entryLevel/star_grey.png" />
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>


          <div class="man-info" v-for="(item, index) in list" :key="index">
            <div class="info-top">
              <div class="info-left">
                <img v-show="item.saveFlg == 1" src="../../assets/images/entryLevel/star_orange.png">
                <img v-show="item.saveFlg == 0" src="../../assets/images/entryLevel/star_grey.png">
                <div class="info-status " :style="{background: item.color}">{{item.detailContent}}</div>
                <p>エントリー日：{{item.entryDate}}</p>
              </div>

              <el-select multiple class="select" @change="selectTeacher(item)" v-model="item.compUserIds" placeholder="" :disabled="item.status == -1">
                <el-option v-for="(item2, index2) in selUsers" :key="index2" :label="item2.kbName" :value="item2.id + ''"></el-option>
              </el-select>

<!--              <div class="export">-->
<!--                <div @click.stop="changeStatusActive()" class="export-title">-->
<!--                  <span>{{status}}</span>-->
<!--                  <img src="../../assets/images/arrow_down.png">-->
<!--                </div>-->
<!--                <div @click.stop="changeStatusActive" :class="statusActive ? 'active' : ''" class="export-list">-->
<!--                  <p @click="selectStatus('-')">-</p>-->
<!--                  <p @click="selectStatus('中川 梓')">中川 梓</p>-->
<!--                  <p @click="selectStatus('葛 尚也')">葛 尚也</p>-->
<!--                  <p @click="selectStatus('唯野 雄幸')">唯野 雄幸</p>-->
<!--                  <p @click="selectStatus('山岸 史弥')">山岸 史弥</p>-->
<!--                </div>-->
<!--              </div>-->
            </div>

            <div class="info-bottom">
              <div class="head">
<!--                <a href="/#/index/entryLevelDetail"><img :src="item.imageUrl"/></a>-->
                <img v-if="item.imageUrl" @click="nav(item)" :src="item.imageUrl"/>
                <img v-else @click="nav(item)" src='../../assets/images/default_head.png'/>
<!--                <a :href="'/#/index/entryLevelDetail?id=' + item.id"></a>-->
              </div>
              <div class="info-detail">

                <div class="info-stu">
                  <div>
                    <p class="info-id">{{item.nameRoma}}</p>
                    <p @click="nav(item)" style="cursor: pointer" class="info-desc">{{item.name}}<span v-if="item.status == -1">【退会申請中】</span></p>
                    <p class="info-name">学生ID：{{item.userId}}</p>
                  </div>
                  <div>
                    <p class="info-school"><span>学校名</span>｜{{item.schoolName}}</p>
                    <p class="info-school"><span>学　部</span>｜{{item.faculty}}</p>
                    <p class="info-school"><span>学　科</span>｜{{item.facultyDepartment}}</p>
                    <p class="info-school"><span>卒業年度</span>｜{{item.graduationYearName}}</p>
                  </div>
                </div>
                <div class="info-process">
                  <div class="process-top">
                    <p>■ 選考履歴</p>
                    <div>
                      <div class="process-list" v-for="(item2, index2) in item.flowList" :key="index2">
                        <div :style="{background: item.flowList.length - 1 == index2 ? item.color : ''}" class="process-item">
                          <span>{{item2.content}}</span>
                          <span>{{item2.entryDate}}</span>
                        </div>
                        <img v-if="!(item.flowList.length - 1 == index2)" src="../../assets/images/entryLevel/arrow_right.png">
                      </div>

                    </div>

<!--                    <p>■ WinC Audition参加履歴</p>-->
<!--                    <div class="company-list">-->
<!--                      <div class="company-item">-->
<!--                        <p class="company-title">2023/04/18 東京開催</p>-->
<!--                        <div class="company-bottom">-->
<!--                          <p class="matching-title">マッチング数</p>-->
<!--                          <p class="matching-num" style="color: #424242;">0<span>社</span></p>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>説明会エントリー</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>エントリー</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>面接</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>内定</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>辞退</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="company-item">-->
<!--                        <p class="company-title">2023/04/18 東京開催</p>-->
<!--                        <div class="company-bottom">-->
<!--                          <p class="matching-title">マッチング数</p>-->
<!--                          <p class="matching-num">2<span>社</span></p>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>説明会エントリー</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>エントリー</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>面接</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>内定</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                          <div class="matching-detail">-->
<!--                            <span>辞退</span>-->
<!--                            <span>一</span>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "entryLevel",


  data() {
    return {

      masterName: [
        {name: '卒業年度', value: 'graduationYear'},
        {name: '学校区分', value: 'schoolDivision'},
        {name: '業界', value: 'expectedIndustry'},
        {name: '職種', value: 'expectedOccupation'},
        {name: '都道府県', value: 'expectedWorkplace'},
        {name: '社長特徴', value: 'mostImportant'},
        {name: '就職活動で企業選びの軸にしていること', value: 'importanceOfComp'},
        {name: '学生時代に力を入れたこと', value: 'studentEffort'},
      ],
      search: {
        graduationYear: [],
        schoolDivision: [],
        detailContent: [],
        expectedIndustry: [],
        expectedOccupation: [],
        expectedWorkplace: [],
        mostImportant: [],
        importanceOfComp: [],
        studentEffort: [],
        // searchRange: [],
        group: [],
        pageSize: 30,
        pageCount: 0,
        pageIndex: 1,
      },
      searchItem: {},




      list: [],
      pageCount: 0,

      //学生担当者
      selUsers: [],
      tech: [],

      userInfo: {},

      pageSize: 30,
      pageIndex: 1,
      exportActive: false,
      statusActive: false,
      status: '-',

      value: '',

      radio: [],

      value1: '',

      detailActive: false,
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    clear() {
      this.search = {
        graduationYear: [],
        schoolDivision: [],
        detailContent: [],
        expectedIndustry: [],
        expectedOccupation: [],
        expectedWorkplace: [],
        mostImportant: [],
        importanceOfComp: [],
        studentEffort: [],
        // searchRange: [],
        group: [],
        pageSize: 30,
        pageIndex: 1,
      };

      this.searchList();
    },

    async init() {
      this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
      await this.initSearch();


      if(this.$route.query.params) {
        this.search =  JSON.parse(decodeURI(this.$route.query.params));
      }

      console.log(this.search)
      await this.searchList();
    },

    async initSearch() {
      let res = null;

      for (let i = 0; i < this.masterName.length; i++) {
        this.getSearchItem(i);
      }
      res = await this.$axios({url: `/common/flowStatusSel`, data: {}, method: "post"});
      this.searchItem['detailContent'] = res.data;
      this.search.detailContent=[this.$route.query.detailContent];


      res = await this.$axios({url: `/compUser/selUsers`, data: {compId: this.userInfo.compId}, method: "post"});
      this.selUsers = res.data;

      // console.log(this.searchItem)

      this.$forceUpdate();
    },

    async getSearchItem(i) {
      let res = await this.$axios({url: `/common/getMasterListByName/${this.masterName[i].name}`, data: {}, method: "get"});
      this.searchItem[this.masterName[i].value] = res.data;
    },


    async searchList() {

      //エントリー期間の終了日は、開始日よりも後の日付を設定してください
      // entryDateFrom: this.search.entryDateFrom,
      //     entryDateTo: this.search.entryDateTo,
      if(this.search.entryDateFrom > this.search.entryDateTo) {
        this.$confirm('エントリー期間の終了日は、開始日よりも後の日付を設定してください', '', {
          confirmButtonText: 'OK',
          cancelButtonText: '取消',
          showCancelButton: false,
          center: true
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      }
      let data = {
        graduationYear: this.search.graduationYear.join(),
        detailContent: this.search.detailContent.join(),
        expectedIndustry: this.search.expectedIndustry.join(),
        expectedOccupation: this.search.expectedOccupation.join(),
        expectedWorkplace: this.search.expectedWorkplace.join(),
        importanceOfComp: this.search.importanceOfComp.join(),
        mostImportant: this.search.mostImportant.join(),
        schoolDivision: this.search.schoolDivision.join(),
        // searchRange: this.search.searchRange.join(),
        saveFlg: this.search.saveFlg,
        studentEffort: this.search.studentEffort.join(),
        compId: this.userInfo.compId,
        pageIndex: this.search.pageIndex,
        pageSize: this.search.pageSize,
        operatorId: this.userInfo.id,
        studentOnlyFlg: 0,
        exceptInternalFlg: 0,
        exceptDismissFlg: 0,
        keyword: this.search.keyword,
        entryDateFrom: this.search.entryDateFrom,
        entryDateTo: this.search.entryDateTo,
        expectedAnnualSalaryMin: this.search.expectedAnnualSalaryMin,
        expectedAnnualSalaryMax: this.search.expectedAnnualSalaryMax
      };

      // this.search.studentOnlyFlg = 0;
      // this.search.exceptInternalFlg = 0;
      // this.search.exceptDismissFlg = 0;
      let that = this;
      this.search.group.forEach((item) => {
        data[item] = 1;
      })

      // if(this.search.searchRange.length > 0) {
      //   data.entryDateFrom = this.search.searchRange[0];
      //   data.entryDateTo = this.search.searchRange[1];
      // }


      // this.search.saveFig = 0;
      if(!(this.search.saveFig)) {
        this.search.saveFig = 0;
      }
      this.search.compId = this.userInfo.compId;
      this.search.pageIndex = this.search.pageIndex;
      // this.search.pageSize = this.pageSize;
      this.search.operatorId = this.userInfo.id;


      let res = await this.$axios({url: `/entry/list`, data: data, method: "post"});
      if(res.code == '000') {
        res.data.items.forEach(item => {
          // console.log(item.compUserIds)
          if(item.compUserIds) {
            // console.log("ssss")
            item.compUserIds = item.compUserIds.split(',');
          } else {
            item.compUserIds = [];
          }

          // item.compUserIds = item.compUserIds.split(',');
        })


        this.list = res.data.items;
        this.search.pageCount = res.data.totalCount;
        console.log(this.list)
        this.$forceUpdate();
      }
      // console.log(this.search)

    },

    nav(item) {
      this.$router.push({
        path: '/index/entryLevelDetail',
        query: {
          id: item.id,
          params: encodeURI(JSON.stringify(this.search))
        }
      })

    },

    selectIsFlg(type) {
      this.search.saveFlg = type;
      this.searchList();
      // console.log(type)
      this.$forceUpdate()
    },


    handleCurrentChange(val) {
      // console.log(val, this.pageIndex)
      this.search.pageIndex = val;
      this.searchList();
    },

    changePageSize(page) {
      this.search.pageSize = page;
      this.searchList();
    },

    handleBasicExport(){
      this.$confirm('エントリー情報を一括ダウンロードしますか？', '', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        center: true
      }).then(() => {

        this.changeExportActive();

      })
    },
    exportExcel(res, fileName){
      if(res.type=="application/json"){ //no data to download
        const reader= new FileReader();
        reader.onload=function(){
          const {msg}= JSON.parse(reader.result);
          Message({
            showClose: true,
            message: msg,
            type: "error",
          });
        };
        reader.readAsText(res);
      }
      else {
        //console.log("res", res)
        const link = document.createElement('a')
        let blob = new Blob([res], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', fileName+ '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    async changeExportActive() {

      let data = {
        graduationYear: this.search.graduationYear.join(),
        detailContent: this.search.detailContent.join(),
        expectedIndustry: this.search.expectedIndustry.join(),
        expectedOccupation: this.search.expectedOccupation.join(),
        expectedWorkplace: this.search.expectedWorkplace.join(),
        importanceOfComp: this.search.importanceOfComp.join(),
        mostImportant: this.search.mostImportant.join(),
        schoolDivision: this.search.schoolDivision.join(),
        // searchRange: this.search.searchRange.join(),
        saveFlg: this.search.saveFlg,
        studentEffort: this.search.studentEffort.join(),
        compId: this.userInfo.compId,
        pageIndex: this.search.pageIndex,
        pageSize: this.search.pageSize,
        operatorId: this.userInfo.id,
        studentOnlyFlg: 0,
        exceptInternalFlg: 0,
        exceptDismissFlg: 0,
        keyword: this.search.keyword,
        entryDateFrom: this.search.entryDateFrom,
        entryDateTo: this.search.entryDateTo
      };

      // this.search.studentOnlyFlg = 0;
      // this.search.exceptInternalFlg = 0;
      // this.search.exceptDismissFlg = 0;
      let that = this;
      this.search.group.forEach((item) => {
        data[item] = 1;
      })

      // if(this.search.searchRange.length > 0) {
      //   data.entryDateFrom = this.search.searchRange[0];
      //   data.entryDateTo = this.search.searchRange[1];
      // }

      data.token = this.userInfo.token;


      let res = await this.$axios({url: `/entry/export`,
        responseType: 'blob',
        data: data, method: "post"});

      this.exportExcel(res, 'entry');


    },


    changeStatusActive() {
      this.statusActive = !this.statusActive;
    },

    selectStatus(value) {
      this.status = value;
    },

    async selectTeacher(item) {

      let res = await this.$axios({url: `/entry/updCompUser`, data: {
          compUserIds: item.compUserIds.join(','),
          id: item.id,
          "operatorId": this.userInfo.id,
        }, method: "post"});

      if(res.code == '000') {
        this.$message({
          type: 'success',
          message: res.message
        });

      }
    },


    cancelActive() {
      // console.log("sss1")
      this.exportActive = false;
      this.statusActive = false;
    }
  }

}
</script>

<style scoped src="../../style/entryLevel.css">

</style>
